import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModText from "../../../components/Chronology/Modules/ModText";
import InlineLink from "../../../components/Chronology/Modules/InlineLink";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModDailyHighlight from "../../../components/Chronology/Modules/ModDailyHighlight";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";
import { StrongWhite } from "../../../components/Textstyles/Styles";
import ModDotList from "../../../components/Chronology/Modules/ModDotList";

export const frontmatter = {
  title: "Día 97",
  week: "Semana 14",
  day: "18",
  month: "Jun",
  monthNumber: "06",
  date: "2020-06-18",
  path: "/cronologia/semana-14#dia-18-jun/",
};

const Day97 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDottedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En los últimos siete días se han diagnosticado en{" "}
          <strong>España</strong> 1.553 casos mediante pruebas PCR. En ese mismo
          periodo, 89 personas han requerido hospitalización (5,7% de los
          diagnósticos), de los que nueve han sido ingresos en la UCI, y se han
          producido 52 fallecimientos.
        </ModText>
        <ModDailyHighlight>
          <StrongWhite>Pekín</StrongWhite> eleva su estado de alerta sanitaria
          al nivel II al descubrir 137 nuevas infecciones por COVID-19
        </ModDailyHighlight>

        <ModText>
          El ministro de Sanidad, Salvador Illa, ha asegurado hoy en la Comisión
          para la Reconstrucción Social y Económica, que es necesario
          desarrollar la Ley General de Salud Pública cuyo objetivo es lograr un
          sistema preventivo y no solo curativo. Lsa acciones prioritarias son:
        </ModText>
        <ModImage
          src="/images/svg/ministro-ruedaprensa-anunciooficial.svg"
          alt="ministro sanidad"
        />
        <ModDotList>Tener reservas de material esencial</ModDotList>
        <ModDotList>
          Reforzar los sistemas de vigilancia epidemiológica
        </ModDotList>
        <ModDotList>I+D y respuesta común internacional</ModDotList>
        <ModDotList>Atención Primaria e integración sociosanitaria</ModDotList>
        <ModDotList>Cuidar a los que nos cuidan</ModDotList>
        <ModText>
          El Ministerio de Turismo ha lanzado el distintivo 'Responsible
          Tourism' para todos aquellos establecimientos que siguen las{" "}
          <InlineLink link="https://www.mincotur.gob.es/es-es/COVID-19/Paginas/Guias-sector-turistico.aspx">
            Guías de medidas
          </InlineLink>{" "}
          para la reducción del contagio por COVID-19 en el sector turístico.
        </ModText>
        <ModText>
          Se trata de un distintivo gratuito, que se puede descargar en la
          página web del Ministerio de Industria, Comercio y Turismo tras{" "}
          <InlineLink link="https://sedeaplicaciones.minetur.gob.es/turismoresponsable/Guías: ">
            rellenar un formulario
          </InlineLink>{" "}
          en el que el establecimiento muestra su compromiso a seguir las Guías.
          El distintivo es personal e intransferible y habrá de renovarse cada
          año.
        </ModText>
        <ModText>
          El Ministerio de Justicia ha elaborado una guía con recomendaciones
          para la celebración de los juicios telemáticos, que son una de las
          medidas implantadas a raíz de la crisis del COVID19, para garantizar
          la seguridad de los ciudadanos, profesionales y funcionarios públicos
          y evitar contagios.
        </ModText>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day97;
